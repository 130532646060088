.claims-service {
  padding: 40px 20px 50px;
  @media (min-width: 768px) {
    padding: 60px 32px 50px;
  }
  @media (min-width: 1024px) {
    padding: 0px;
  }
  .service_column {
    @media (max-width: 768px) {
      padding: 30px 15px 15px 15px;
    }
    @media (max-width: 992px) {
      padding: 5% 2% 2% 2%;
    }
  }

  .elementor-element-populated {
    height: 280px;
    background-position: center center;
    background-size: cover;
    position: relative;
    @media (min-width: 767px) {
      min-height: 467px;
    }
    @media (min-width: 991px) {
      height: 100%;
    }
  }
  .populated-image1 {
    background-image: url(../../images/claims-background.jpg);
  }
  .populated-image2 {
    background-image: url(../../images/provider-data-management-background.jpg);
  }
  .populated-image3 {
    background-image: url(../../images/configuration-background.jpg);
  }
  .populated-image4 {
    background-image: url(../../images/training-background.jpg);
  }
  .populated-image5 {
    background-image: url(../../images/operational-readiness-background.jpg);
  }
  .elementor-background-overlay {
    background-color: #004449;
    opacity: 0.65;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
  }
  .elementor-image-box-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .elementor {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  .support {
    background-image: url(../../images/black-sand.jpg);
    background-position: center center;
    background-size: cover;
    position: relative;
    height: 100%;
    .button-wrapper {
      a {
        padding: 4px 16px;
        text-decoration: none;
        width: fit-content;
        border: 1pt solid #d19b2d;
        color: #d19b2d;
        &:hover {
          background-color: #ffffff47;
        }
      }
      svg {
        width: 75px;
        height: auto;
      }
    }
  }
}

.service-tabs {
  .nav-item {
    @media (max-width: 767px) {
      .tab-btn {
        font-size: 12px;
        padding: 8px;
      }
    }
    .active {
      background-color: #4092df !important;
      color: #ffffff !important;
    }
    .tab-btn {
      color: #000000;
      width: fit-content;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }
}

.service-box {
  position: relative;
}
