.blogs {
  .blogs-header {
    position: relative;
    .hdr-blog {
      position: absolute;
      bottom: 20px;
      background-color: rgba(40, 60, 94, 0.5);
    }
  }
  .left-side {
    ul {
      list-style: none;
      a{
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
