.footer-sec {
  clip-path: polygon(50% 0%, 100% 10%, 100% 100%, 0 100%, 0 10%);
  img {
    width: 36px;
    @media (min-width: 767px) {
      width: 60px;
    }
  }
}

.bg-footer {
  background: linear-gradient(0deg, #021c36 0, #043769);
  background-image: linear-gradient(0deg, #043769 0, #021c36);
}

.footer-text {
  position: relative;
  top: 5px;
  @media (min-width: 767px) {
    top: 10px;
  }
}

.line{
 &::after {
  border-top: 1px solid;
  border-color: inherit;
  content: "";
  display: table-cell;
  position: relative;
  top: 0.5em;
  width: 100%;
  left: 1.5%;
}
}
.contact_detail {
  a {
    color: #2c4167;
  }
  i {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 18px;
    margin: 0 3px 3px 0;
    padding: 0 0px 0 0;
    background-color: #2c4167;
    background-color: rgba(0, 0, 0, 0.3);
  }
  &:hover {
    .add-text {
      color: #45aaff;
    }
    i {
      background-color: #45aaff;
    }
  }
}
