@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;900&display=swap");
body {
  margin: 0;
  background-color: #efefef !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.lh-225 {
  line-height: 22.5px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.br-f9-1 {
  border: 1px solid #4eabf9;
}
.txt-clrf9 {
  color: #4eabf9;
}
.txt-clr67 {
  color: #2c4167;
}

.fw-100 {
  font-weight: 100;
}
.fw-900 {
  font-weight: 900;
}

.bg-clrf9 {
  background-color: #4eabf9;
}
.bg-clr49 {
  background-color: #004449;
}
.bg-clref {
  background-color: #efefef;
}
.bg-clr70 {
  background-color: #304770;
}

.fnt-fly {
  font-family: Raleway, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.error {
  color: rgb(118, 6, 6);
}

.update-btn {
  padding: 8px 24px !important;
  @media (min-width: 767px) {
    padding: 12px 32px !important;
  }
  border-radius: 0px !important;
  color: #ffffff;
  &:hover {
    background-color: #4eabf9;
    color: #ffffff;
  }
}

.btn-secondary {
  color: #ffffff;
  font-weight: 700;
  border: 0px;
  border-radius: 0px !important;
  background-color: #304770 !important;
  border-color: transparent !important;
  &:hover {
    color: #ffffff !important;
    background-color: #21304c !important;
    border-color: rgba(0, 0, 0, 0) !important;
  }
}

.send-btn{
  padding: 6px 60px !important;
  font-size: 14px !important;
  color: #ffffff;
  font-weight: 700;
  border-radius: 0px !important;
  border: 1px solid #1293ff !important;
  background-color: #1293ff !important;
  &:hover {
    border: 1px solid #fff !important;
    color: #ffffff !important;
    background-color: #21304c !important;
  }
}


.fnt-10 {
  font-size: 10px;
}
.fnt-13 {
  font-size: 13px;
}
.fnt-14 {
  font-size: 14px;
}
.fnt-15 {
  font-size: 15px;
}
.fnt-16 {
  font-size: 16px;
}
.fnt-18 {
  font-size: 18px;
}
.fnt-20 {
  font-size: 20px;
}
.fnt-22 {
  font-size: 22px;
}
.fnt-24 {
  font-size: 24px;
}
.fnt-36 {
  font-size: 36px;
}

@media (min-width: 768px) {
  .fnt-md-24 {
    font-size: 24px;
  }
  .fnt-md-30 {
    font-size: 30px;
  }
  .fnt-md-36 {
    font-size: 36px;
  }
  .fnt-md-40 {
    font-size: 40px;
  }
  .fnt-md-45 {
    font-size: 45px;
  }
  .fnt-md-56 {
    font-size: 56px;
  }
  .fnt-md-60 {
    font-size: 60px;
  }
}
@media (min-width: 992px) {
  .fnt-md-60 {
    font-size: 60px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1170px !important;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #4eabf9;
  box-shadow: inset 0 0 3px #4eabf9;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
